var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "기본정보" },
            },
            [
              _c(
                "template",
                { slot: "card-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _c("c-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editable,
                            expression: "editable",
                          },
                        ],
                        attrs: {
                          url: _vm.saveUrl,
                          isSubmit: _vm.isSave,
                          param: _vm.trainingScenario,
                          mappingType: _vm.mappingType,
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveInfo,
                          btnCallback: _vm.saveCallback,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-sm-12 col-md-6 col-lg-6" },
                  [
                    _c("c-text", {
                      attrs: {
                        required: "",
                        editable: _vm.editable,
                        label: "시나리오명",
                        name: "scenarioName",
                      },
                      model: {
                        value: _vm.trainingScenario.scenarioName,
                        callback: function ($$v) {
                          _vm.$set(_vm.trainingScenario, "scenarioName", $$v)
                        },
                        expression: "trainingScenario.scenarioName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3" },
                  [
                    _c("c-plant", {
                      attrs: {
                        required: "",
                        editable: _vm.editable,
                        type: "edit",
                        name: "plantCd",
                      },
                      model: {
                        value: _vm.trainingScenario.plantCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.trainingScenario, "plantCd", $$v)
                        },
                        expression: "trainingScenario.plantCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3" },
                  [
                    _c("c-checkbox", {
                      attrs: {
                        editable: _vm.editable,
                        isFlag: true,
                        label: "사용여부",
                        name: "useFlag",
                      },
                      model: {
                        value: _vm.trainingScenario.useFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.trainingScenario, "useFlag", $$v)
                        },
                        expression: "trainingScenario.useFlag",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "c-table",
            {
              ref: "table",
              staticClass: "q-mt-sm",
              attrs: {
                title: "시나리오 상황",
                merge: _vm.grid.merge,
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.trainingScenario.situations,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                editable: _vm.editable,
                selection: "multiple",
                rowKey: "sopFireFightingTraningScenarioSituationId",
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      _c(
                        "div",
                        [
                          _c(
                            "q-btn-group",
                            {
                              staticClass: "ColumInnerBtnGroup",
                              attrs: { outline: "" },
                            },
                            [
                              _vm.editable
                                ? _c("q-btn", {
                                    staticClass: "ColumInnerBtn",
                                    attrs: {
                                      label: "",
                                      icon: "add",
                                      color: "light-blue",
                                      "text-color": "white",
                                      align: "center",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.addRow(props)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("c-textarea-column", {
                        attrs: {
                          editable: _vm.editable,
                          col: col,
                          props: props,
                        },
                        on: {
                          datachange: function ($event) {
                            return _vm.datachange(props, col)
                          },
                        },
                      }),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: {
                              showLoading: false,
                              label: "추가",
                              icon: "add",
                            },
                            on: { btnClicked: _vm.addTarget },
                          })
                        : _vm._e(),
                      _vm.editable
                        ? _c("c-btn", {
                            attrs: {
                              disabled:
                                !_vm.trainingScenario.situations ||
                                _vm.trainingScenario.situations.length === 0,
                              label: "제외",
                              icon: "remove",
                            },
                            on: { btnClicked: _vm.deleteTarget },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }